import type { ItemModel } from './types.ts';
import type { DataObjectEvents } from './DataObject.ts';

export type DataObjectEvent<T extends ItemModel, K extends keyof DataObjectEvents<T>> = DataObjectEvents<T>[K];

export type DataObjectExtensionMethods<T extends ItemModel> = {
    'overrideEmit': <K extends keyof DataObjectEvents<T>>(pEvent: K, pOverride: DataObjectEvent<T, K>) => void,
    'clearEmitOverride': (pEvent: keyof DataObjectEvents<T>) => void
};
export type DataObjectExtensionCallback<T extends ItemModel, K extends keyof DataObjectExtensionMethods<T>> = (pMethod: K, ...args: Parameters<DataObjectExtensionMethods<T>[K]>) => ReturnType<DataObjectExtensionMethods<T>[K]>;

/**
 * Abstact class that can be extended by DataObject extensions. This will provide access to DataObjectExtensionCallback 
 * allowing the extension to execute some of the private DataObject functions.
 */
export default abstract class DataObjectExtension<T extends ItemModel = ItemModel> {
    private _extensionCallback: DataObjectExtensionCallback<T, keyof DataObjectExtensionMethods<T>> 
    constructor(pFunctionExecute: DataObjectExtensionCallback<T, keyof DataObjectExtensionMethods<T>>) {
        this._extensionCallback = pFunctionExecute;
    }
    
    /**
     * Execute protected functions meant for extensions on the DataObject
     */
    protected _executeOnDataObject<K extends keyof DataObjectEvents<T>>(pMethod: 'overrideEmit', pEvent: K, pOverride: DataObjectEvent<T, K>): ReturnType<DataObjectExtensionMethods<T>['clearEmitOverride']>
    protected _executeOnDataObject<K extends keyof DataObjectEvents<T>>(pMethod: 'clearEmitOverride', pEvent: K): ReturnType<DataObjectExtensionMethods<T>['clearEmitOverride']>
    protected _executeOnDataObject<K extends keyof DataObjectExtensionMethods<T>>(pMethod: K, ...args: Parameters<DataObjectExtensionMethods<T>[K]>) {
        return this._extensionCallback(pMethod, ...args);
    }
}  